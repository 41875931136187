@import "~react-vis/dist/style";

@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Pretendard";
  font-weight: 100 900;
  src: url("/fonts/PretendardVariable.woff2") format("woff2-variations");
}

* {
  font-family: "Pretendard", sans-serif;
  font-variant-numeric: tabular-nums;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
